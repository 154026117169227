<template>
    <Toolbar
        :title="title"
        :back-to="userView ? { name: 'AdminUserList' } : null"
        :bread-crumbs="breadCrumbs"
        refresh-button
    >
        <template v-if="userView" v-slot:extension>
            <v-tabs
                    v-if="userView"
                    fixed-tabs
                    show-arrows
                    center-active
            >
                <v-tab :to="{ name: 'AdminUserDetails' }">Summary</v-tab>
                <v-tab :to="{ name: 'AdminUserSessions' }">Sessions</v-tab>
                <v-tab :to="{ name: 'AdminUserHistory' }">History</v-tab>
            </v-tabs>
        </template>
    </Toolbar>
</template>

<script>
import Toolbar from '../templates/Toolbar';
import NewCustomerDialog from '../../dialogs/Customer/NewCustomerDialog';
import AddDIDDialog from '../../dialogs/DID/AddDIDDialog';
export default {
    name: 'AdminToolbar',
    components: { Toolbar },
    data: () => ({
    }),
    props: {
      /*
        This values is used to determine where and how the tool bar is being used.
        Because of the logic used to layout the UI the toolbar is not manipulatable by the children.
        So we use this value to help determine what sub menu to output and to help determine any bread crumbs.
        Note that we would need to use global storage to pass anything to the toolbar from child pages if any information was needed.
        EG a device ID might be needed for the bread crumb route link pointing to the device summary page.
       */
      extension: {
        type: String,
        default: null,
      }
    },
    watch:{
      //clear previous addon breadcrumbs. Important is we are navigating to a page that does not set any addon breadcrumbs.
      $route (to, from){
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: null});
      }
    },
    computed: {
      title() {
          // let title = '';
          // if (this.userView) title += 'User - ';
          // title += this.$route.meta.title ? this.$route.meta.title : '';
          // return title;
        return (!this.isloading ? this.$route.meta.title : 'Loading');
      },
      userView() {
          //return this.$route.matched.some((m) => m.name === 'AdminUserParent');
        return this.extension == 'user';
      },
      /*
      Note that if we are using the CustomerParentPage and the TrunkParentPage then the corrsponding records will be loaded
       */
      breadCrumbs( ) {
        let addons = this.breadCrumbAddons;
        let ret = [];
        if(addons != null){
          ret = addons;
          if(!addons[addons.length - 1].end || addons[addons.length - 1].end == false){
            ret.push({
              name:this.$route.name,
              label:this.$route.meta.title,
              params:this.$route.params,
            })
          }
        }else{
          ret.push({
            name:this.$route.name,
            label:this.$route.meta.title,
            params:this.$route.params,
          })
        }
        return ret;
      },
      breadCrumbAddons( ){
        return this.$store.state.data['breadCrumbsAddon'];
      },
    },
};
</script>
